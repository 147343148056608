<!-- 文件中文名: 推荐网络业绩查询 -->
<template>
  <div class="network-pic app-container">
    <div class="page-container account">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" size="mini">
          <!--经销商编号-->
          <el-form-item :label="$t('miMemberNetwork.memberNo1')+':'" prop="memberNo">
            <el-input @input="value=>listQuery.memberNo=value.toUpperCase()"  v-model.trim="listQuery.memberNo" size="mini"/>
          </el-form-item>
          <el-form-item :label="$t('miMemberNetwork.memberNo2')+':'" >
            <el-input @input="value=>listQuery.memberEnd=value.toUpperCase()"  v-model.trim="listQuery.memberEnd" size="mini"/>
          </el-form-item>
          <el-row>
            <el-col :xl="12" :md="14" :xs="24">
              <el-form-item :label="$t('title.date')">
                <!-- 时间起始 -->
                <el-form-item prop="fromDate">
                  <el-date-picker
                      v-model="listQuery.fromDate"
                      type="date"
                      style="width: 135px;"
                      value-format="yyyy-MM-dd"
                      :placeholder="$t('Calendar.TT.SEL_DATE')"
                      clearable
                  />
                  &nbsp;&nbsp; - &nbsp;&nbsp;
                </el-form-item>
                <el-form-item prop="toDate">
                  <el-date-picker
                      v-model="listQuery.toDate"
                      type="date"
                      style="width: 135px;"
                      value-format="yyyy-MM-dd"
                      :placeholder="$t('Calendar.TT.SEL_DATE')"
                      clearable
                  />
                </el-form-item>

                <!--            <el-form-item>-->
                <!--              &lt;!&ndash;向下查子节点&ndash;&gt;-->
                <!--              <el-radio  v-model="listQuery.upOrDown" label="down" style="margin-left: 10px">-->
                <!--                {{ $t('tree.down') }}-->
                <!--              </el-radio>-->
                <!--              &lt;!&ndash;向上查父节点&ndash;&gt;-->
                <!--              <el-radio  v-model="listQuery.upOrDown" label="up">{{ $t('tree.up') }}</el-radio>-->
                <!--            </el-form-item>-->
                <!-- 时间结束 -->
              </el-form-item>
            </el-col>
            <el-col :xl="12" :md="10" :xs="24">
              <div style="margin-bottom: 25px">
                <el-button :loading="btnLoading" icon="el-icon-search" plain type="primary" size="mini" @click="handleFilter">
                  {{ $t('operation.button.search') }}
                </el-button>
              </div>
            </el-col>
          </el-row>

          <!--搜索-->

        </el-form>
        <p v-if="btnLoading" style="font-size: 2.5rem; color: #e9792e; font-weight: 700">
          <i class="el-icon-loading"/>
          {{ this.$t('query_data_wait') }}
        </p>
      </div>
      <!--      <div style="line-height: 20px;font-size: 18px;">-->
      <!--        <span>{{$t('Tips')}}</span>-->
      <!--        <el-row>-->
      <!--          <el-col :sm="12" :xs="24">-->
      <!--            报单人数-->
      <!--            <span style="font-size: 16px"><span style="color: #000;font-size: 30px;margin-right: 10px">▬</span>{{$t('Number.of.applicants')}}</span>-->
      <!--          </el-col>-->
      <!--          <el-col :sm="12" :xs="24">-->
      <!--            首购升级PV业绩-->
      <!--            <span style="font-size: 16px"><span style="color: #69cf91;font-size: 30px;margin-right: 10px">▬</span>{{$t('First.purchase.upgrade.performance')}}</span>-->
      <!--          </el-col>-->
      <!--          <el-col :sm="12" :xs="24">-->
      <!--            重消PV业绩-->
      <!--            <span style="font-size: 16px"><span style="color: #ff3052;font-size: 30px;margin-right: 10px">▬</span>{{$t('Recharge.PV.performance')}}</span>-->
      <!--          </el-col>-->
      <!--          <el-col :sm="12" :xs="24">-->
      <!--            总报单PV业绩-->
      <!--            <span style="font-size: 16px"><span style="color: #ff4e38;font-size: 30px;margin-right: 10px">▬</span>{{$t('PV.performance.of.total.declaration')}}</span>-->
      <!--          </el-col>-->
      <!--          <el-col :sm="12" :xs="24">-->
      <!--            个人首购升级业绩-->
      <!--            <span style="font-size: 16px"><span style="color: blue;font-size: 30px;margin-right: 10px">▬</span>{{$t('Personal.first.purchase')}}</span>-->
      <!--          </el-col>-->
      <!--          <el-col :sm="12" :xs="24">-->
      <!--            个人重消业绩-->
      <!--            <span style="font-size: 16px"><span style="color: green;font-size: 30px;margin-right: 10px">▬</span>{{$t('Individual.cancellation.performance')}}</span>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--      </div>-->
      <div v-if="membernum === 1" class="mytree" style="width: 100%;height: 600px;overflow: auto">
        <el-tree
            ref="tree"
            empty-text=""
            :expand-on-click-node="false"
            v-if="treeIf"
            node-key="memberNo"
            lazy
            :props="props"
            :load="loadNode"
            @node-click="nodeClick"
        >
          <template slot-scope="scope">
            <div :class="colorOne[scope.data.levelType ? (scope.data.levelType).substr(0,1) : '']"
                 style="color: #333; border: 2px #e396cd solid; border-radius: 7px;">
              <table class="view-table table-content">
                <tr v-show="scope.data.recommendLayer!==0">
                  <td rowspan="5">
                    {{ scope.data.recommendLayer }}
                  </td>
                </tr>
                <tr>
                  <td class="tl" @click.stop>
                    <el-popover trigger="click" v-model="scope.data.visible">
                      <div
                          :class="colorOne[scope.data.levelType ? (scope.data.levelType).substr(0,1) : '']"
                          v-loading="doLoading"
                      >
                        <table class="view-table">
                          <tr>
                            <!--姓名-->
                            <td v-if="language==='zh_CN' " class="pClass tl">{{ $t('person.name') }}</td>
                            <td v-else class="pClass tl">Usario</td>
                            <td class="pClass">{{ popoverData.name }}</td>
                          </tr>
                          <tr>
                            <!--注册日期-->
                            <td v-if="language==='zh_CN' " class="pClass tl">{{ $t('miMember.registerDate') }}</td>
                            <td v-else class="pClass tl">Feche de registro</td>
                            <td class="pClass">{{ popoverData.registerDate }}</td>
                          </tr>
                          <tr>
                            <td class="pClass tl">{{$t('member.platinum')}}</td>
                            <td class="pClass">{{ formatNumber(Number(popoverData[40])) }}</td>
                          </tr>
                          <tr>
                            <td class="pClass tl">{{$t('member.level.gold.add')}}</td>
                            <td class="pClass">{{ formatNumber(Number(popoverData[30])) }}</td>
                          </tr>
                          <tr>
                            <td  class="pClass tl">{{$t('member.level.gold')}}</td>
                            <td class="pClass">{{ formatNumber(Number(popoverData[20])) }}</td>
                          </tr>
                          <tr>
                            <td   class="pClass tl">{{$t('permit.silverClass')}}</td>
                            <td class="pClass">{{ formatNumber(Number(popoverData[10])) }}</td>
                          </tr>
                          <tr>
                            <!--报单人数-->
                            <td v-if="language==='zh_CN' " class="pClass tl">{{ $t('Number.of.applicants') }}</td>
                            <td v-else class="pClass tl">Cantidad total del pedido</td>
                            <td class="pClass">{{ formatNumber(Number(popoverData.bdrs)) }}</td>
                          </tr>
                          <tr>
                            <!--本月报单人数-->
                            <td class="pClass tl">
                              <span v-if="language === 'zh_CN'" class="pClass">{{ $t('rpt.currentAmount') + $t('Number.of.applicants') }}</span>
                              <span v-else class="pClass">Tiene pedido mes actual</span>
                            </td>
                            <td class="pClass">{{ formatNumber(Number(popoverData.bybdrs)) }}</td>
                          </tr>
                        </table>
                      </div>
                      <p
                          class="pClass"
                          slot="reference"
                          style="cursor: pointer;color: #3967FF;margin: 0"
                          @click="showPopover(scope.data)">
                        {{ scope.data.memberNo }}
                      </p>
                    </el-popover>
                  </td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">Покупка</p>
                    <!--订单-->
                    <p v-else class="pClass">{{ $t('po.orders') }}</p>
                  </td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">Личный</p>
                    <p v-if="language === 'ru_RU'" class="pClass">ТО</p>
                    <!--个人业绩-->
                    <p v-else class="pClass">{{ $t('mimember.onesell') }}</p>
                  </td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">1 линия</p>
                    <!--第一层的业绩-->
                    <p v-else class="pClass">{{ $t('FirstLayerPerformance') }}</p>
                  </td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">Групповой</p>
                    <p v-if="language === 'ru_RU'" class="pClass">ТО</p>
                    <!--团体的业绩-->
                    <p v-else class="pClass">{{ $t('TeamPerformance') }}</p>
                  </td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">Кол-во</p>
                    <p v-if="language === 'ru_RU'" class="pClass">заказов</p>
                    <!--保单人数-->
                    <p v-else class="pClass">{{ $t('Number.of.applicants') }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="tl">
                    <p style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100px;margin-top: 0;margin-bottom: 0">
                      {{ scope.data.name }}
                    </p>
                  </td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">Первичная</p>
                    <!--首购-->
                    <p v-else class="pClass">{{ $t('po.order_type.1_es_ES') }}</p>
                  </td>
                  <!--个人业绩（首购）-->
                  <td>{{ formatNumber(scope.data.sgsellgeren) }}</td>
                  <!--第一层的业绩（首购）-->
                  <td>{{ formatNumber(scope.data.oneSGsellgeren) }}</td>
                  <!--团体的业绩(首购)-->
                  <td>{{ formatNumber(scope.data.teamSGsellgeren) }}</td>
                  <!--保单人数（首购）-->
                  <td>{{ formatNumber(scope.data.cnt17) }}</td>
                </tr>
                <tr>
                  <td class="tl">{{ scope.data.levelType == 0 ? '-' : $lt(initDict.levelType, scope.data.levelType) }}</td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">Повторная</p>
                    <!--重销单-->
                    <p v-else class="pClass">{{ $t('poOrder.uprec.order_es_ES') }}</p>
                  </td>
                  <!--个人业绩（重销）-->
                  <td>{{ formatNumber(scope.data.cxsellgeren) }}</td>
                  <!--第一层的业绩（重销）-->
                  <td>{{ formatNumber(scope.data.oneCXsellgeren) }}</td>
                  <!--团体的业绩(重销)-->
                  <td>{{ formatNumber(scope.data.teamCXsellgeren) }}</td>
                  <!--保单人数（重销）-->
                  <td>{{ formatNumber(scope.data.cnt19) }}</td>
                </tr>
                <tr>
                  <td class="tl">{{ scope.data.cardType == 1 ? '-' : $lt(initDict.cardType, scope.data.cardType) }}</td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">Сумма</p>
                    <!--总计-->
                    <p v-else class="pClass">{{ $t('report.allTotal') }}</p>
                  </td>
                  <!--个人业绩（首购+重销）-->
                  <td>{{ formatNumber(Number(scope.data.sgsellgeren) + Number(scope.data.cxsellgeren) ) }}</td>
                  <!--第一层的业绩（首购+重销）-->
                  <td>{{ formatNumber( Number(scope.data.oneSGsellgeren) + Number(scope.data.oneCXsellgeren) ) }}</td>
                  <!--团体的业绩（总额）-->
                  <td>{{ formatNumber( Number(scope.data.teamSGsellgeren) + Number(scope.data.teamCXsellgeren) ) }}</td>
                  <!--报单人数（总额）-->
                  <td>{{ formatNumber( Number(scope.data.cntSum)) }}</td>
                </tr>
              </table>
            </div>
          </template>
        </el-tree>
      </div>
      <div v-if="membernum === 2" class="mytree" style="width: 100%;height: 600px;overflow: auto">
        <el-tree
            v-if="Object.keys(viewData).length"
            ref="tree"
            :loading="btnLoading"
            :indent="0"
            :data="[viewData]"
            :props="defaultProps"
            node-key="memberNo"
            :expand-on-click-node="false"
            @node-click="nodeClick"
        >
          <template slot-scope="scope">
            <div :class="colorOne[scope.data.levelType ? (scope.data.levelType).substr(0,1) : '']"
                 style="color: #333; border: 2px #e396cd solid; border-radius: 7px;">
              <table class="view-table table-content">
                <tr v-show="scope.data.recommendLayer!==0">
                  <td rowspan="5">
                    {{ scope.data.recommendLayer }}
                  </td>
                </tr>
                <tr>
                  <td class="tl" @click.stop>
                    <el-popover trigger="click" v-model="scope.data.visible">
                      <div
                          :class="colorOne[scope.data.levelType ? (scope.data.levelType).substr(0,1) : '']"
                          v-loading="doLoading"
                      >
                        <table class="view-table">
                          <tr>
                            <!--姓名-->
                            <td class="pClass tl">{{ $t('person.name') }}</td>
                            <td class="pClass">{{ scope.data.name }}</td>
                          </tr>
                          <tr>
                            <!--注册日期-->
                            <td class="pClass tl">{{ $t('miMember.registerDate') }}</td>
                            <td class="pClass">{{ scope.data.registerDate }}</td>
                          </tr>
                          <tr>
                            <td class="pClass tl">START</td>
                            <td class="pClass">{{ formatNumber(Number(scope.data[20])) }}</td>
                          </tr>
                          <tr>
                            <td class="pClass tl">STANDART</td>
                            <td class="pClass">{{ formatNumber(Number(scope.data[30])) }}</td>
                          </tr>
                          <tr>
                            <td class="pClass tl">PREMIUM</td>
                            <td class="pClass">{{ formatNumber(Number(scope.data[40])) }}</td>
                          </tr>
                          <tr>
                            <!--报单人数-->
                            <td class="pClass tl">{{ $t('Number.of.applicants') }}</td>
                            <td class="pClass">{{ formatNumber(Number(scope.data.bdrs)) }}</td>
                          </tr>
                          <tr>
                            <!--本月的报单人数-->
                            <td class="pClass tl">
                              <span v-if="language === 'ru_RU'" class="pClass">За текущий месяц</span>
                              <span v-else class="pClass">
                                {{ $t('rpt.currentAmount') + $t('Number.of.applicants') }}</span>
                            </td>
                            <td class="pClass">{{ formatNumber(Number(scope.data.bybdrs)) }}</td>
                          </tr>
                        </table>
                      </div>
                      <p
                          class="pClass"
                          slot="reference"
                          style="cursor: pointer;color: #3967FF;margin: 0"
                          @click="showPopover(scope.data)">
                        {{ scope.data.memberNo }}
                      </p>
                    </el-popover>
                  </td>
                  <td class="tl">
                    <p v-if="language === 'ru_RU'" class="pClass">Покупка</p>
                    <!--订单-->
                    <p v-else class="pClass">{{ $t('po.orders') }}</p>
                  </td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">Личный</p>
                    <p v-if="language === 'ru_RU'" class="pClass">ТО</p>
                    <!--个人业绩-->
                    <p v-else class="pClass">{{ $t('mimember.onesell') }}</p>
                  </td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">1 линия</p>
                    <p v-if="language === 'ru_RU'" class="pClass">период</p>
                    <!--第一层的业绩-->
                    <p v-else class="pClass">{{ $t('FirstLayerPerformance') }}</p>
                  </td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">Групповой ТО</p>
                    <!--团体的业绩-->
                    <p v-else class="pClass">{{ $t('TeamPerformance') }}</p>
                  </td>
                  <td>
                    <p v-if="language === 'ru_RU'" class="pClass">Кол-во заказов</p>
                    <!--保单人数-->
                    <p v-else class="pClass">{{ $t('Number.of.applicants') }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="tl">
                    <p style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100px;margin-top: 0;margin-bottom: 0">
                      {{ scope.data.name }}
                    </p>
                    </td>
                  <td class="tl">
                    <p v-if="language === 'ru_RU'" class="pClass">Первичная</p>
                    <!--首购-->
                    <p v-else class="pClass">{{ $t('po.order_type.1_es_ES') }}</p>
                  </td>
                  <!--个人业绩（首购）-->
                  <td>{{ formatNumber(scope.data.sgsellgeren) }}</td>
                  <!--第一层的业绩（首购）-->
                  <td>{{ formatNumber(scope.data.oneSGsellgeren) }}</td>
                  <!--团体的业绩(首购)-->
                  <td>{{ formatNumber(scope.data.teamSGsellgeren) }}</td>
                  <!--保单人数（首购）-->
                  <td>{{ formatNumber(scope.data.cnt17) }}</td>
                </tr>
                <tr>
                  <td class="tl">{{ scope.data.levelType == 0 ? '-' : $lt(initDict.levelType, scope.data.levelType) }}</td>
                  <td class="tl">
                    <p v-if="language === 'ru_RU'" class="pClass">Повторная</p>
                    <!--重销单-->
                    <p v-else class="pClass">{{ $t('poOrder.uprec.order_es_ES') }}</p>
                  </td>
                  <!--个人业绩（重销）-->
                  <td>{{ formatNumber(scope.data.cxsellgeren) }}</td>
                  <!--第一层的业绩（重销）-->
                  <td>{{ formatNumber(scope.data.oneCXsellgeren) }}</td>
                  <!--团体的业绩(重销)-->
                  <td>{{ formatNumber(scope.data.teamCXsellgeren) }}</td>
                  <!--保单人数（重销）-->
                  <td>{{ formatNumber(scope.data.cnt19) }}</td>
                </tr>
                <tr>
                  <td class="tl">{{ scope.data.cardType == 1 ? '-' : $lt(initDict.cardType, scope.data.cardType) }}</td>
                  <td class="tl">
                    <p v-if="language === 'ru_RU'" class="pClass">Сумма</p>
                    <!--总计-->
                    <p v-else class="pClass">{{ $t('report.allTotal') }}</p>
                  </td>
                  <!--个人业绩（首购+重销）-->
                  <td>{{ formatNumber(Number(scope.data.sgsellgeren) + Number(scope.data.cxsellgeren) ) }}</td>
                  <!--第一层的业绩（首购+重销）-->
                  <td>{{ formatNumber( Number(scope.data.oneSGsellgeren) + Number(scope.data.oneCXsellgeren) ) }}</td>
                  <!--团体的业绩（总额）-->
                  <td>{{ formatNumber( Number(scope.data.teamSGsellgeren) + Number(scope.data.teamCXsellgeren) ) }}</td>
                  <!--保单人数（总额）-->
                  <td>{{ formatNumber( Number(scope.data.cnt17) + Number(scope.data.cnt19)) }}</td>
                </tr>
              </table>
            </div>
          </template>
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import 'vue-organization-chart/dist/orgchart.css'
// getCardType
import {performanceByMember, viewMemberTable} from "@/api/rus/api"
export default {
  name: 'NetworkTree',
  filters: {
    USpace(value) {
      if (value && value.indexOf(' ') !== -1) {
        return value.replace(/\s/g, '　')
      } else {
        return value
      }
    }
  },
  data() {
    return {
      popoverData: {},
      showPopoverList: [], // 展开的popover 长度最多为1
      membernum:1,
      props: {
        label: 'label',
        children: 'children',
        isLeaf: 'leaf'
      },
      cardInfoObj: {},
      treeIf: true,
      btnLoading: false,
      doLoading: false,
      colorOne:['levelType0','levelType1','levelType2','levelType3','levelType4'],
      color:['levelType4','levelType3','levelType2','levelType1', 'levelType0'],
      viewData: {},
      defaultProps: {
        children: 'children',
        label: 'memberNo'
      },
      listQuery: {
        upOrDown: 'down',
        memberNo: '',
        memberEnd: '',
        treeOrView: 'Tree',
        Layer: 0,
        fromDate: '',
        toDate: ''
      },
      initDict: {
        levelType:'ru.member.level',
        cardType: 'ru.mimember.cardtype'
      }
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'language'
    ])
  },
  created() {
    this.getLastMonthDate()
    this.listQuery.memberNo = this.userCode
  },
  methods: {
    nodeClick() {
      this.showPopoverList[0].visible = false
    },
    showPopover(data) {
      this.doLoading = true
      if (!this.showPopoverList.length) {
        this.showPopoverList.push(data)
      } else {
        if (this.showPopoverList[0].memberNo !== data.memberNo) {
          this.showPopoverList[0].visible = false
          this.showPopoverList[0] = data
        }
      }
      viewMemberTable({memberNo: data.memberNo}).then(res => {
        this.doLoading = false
        this.popoverData.name = data.name
        this.popoverData.registerDate = res.data.registerDate
        this.popoverData.bdrs = res.data.bdrs
        this.popoverData.bybdrs = res.data.bybdrs
        this.popoverData['10'] = res.data[10]
        this.popoverData['20'] = res.data[20]
        this.popoverData['30'] = res.data[30]
        this.popoverData['40'] = res.data[40]
      }).catch(err => {
        this.doLoading = false
        console.warn(err)
      })
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        const a = await performanceByMember({...this.listQuery}).then(res => {
          return res.data
        }).catch(err => {
          console.error(err)
        })
        return resolve([a])
      }
      if (node.level >= 1) {
        this.listQuery.memberNo = node.key
        this.listQuery.Layer = 1
        const a = await performanceByMember({...this.listQuery}).then(res => {
          res.data.children.forEach(v => {
            v.recommendLayer = node.level
          })
          return res.data
        }).catch(err => {
          console.error(err)
        })
        return resolve(a.children)
      }
    },
    formatNumber(Num) {
      if (Num) {
        return Num.toLocaleString('fr-FR')
      } else {
        return Num
      }
    },
    getLastMonthDate () {
      var data = new Date();
      var y = data.getFullYear();
      var m = data.getMonth() + 1;
      var d = data.getDate();
      m = m < 10 ? "0" + m : m;
      d = d < 10 ? "0" + d : d;
      if(m === 10){
        this.listQuery.fromDate = y + "-" + "0" + (m-1) + "-" + "29"
      }else if(m === '01'){
        this.listQuery.fromDate = (y-1) + "-01-29"
      }else{
        this.listQuery.fromDate = y + "-" + (m-1) + "-" + "29"
      }
      this.listQuery.toDate = y + "-" + m + "-" + d
    },
    handleFilter() {
      if (!this.listQuery.memberNo) {
        return false
      }
      if(this.listQuery.memberNo && this.listQuery.memberEnd){
        this.membernum = 2
      }else{
        this.membernum = 1
      }
      console.log(this.membernum)
      if (!this.listQuery.fromDate || !this.listQuery.toDate) {
        this.$message.error(this.$t('poOrder.chooseDateRange'))
        return
      }
      this.btnLoading = true
      this.doLoading = true
      this.viewData = []
      this.treeIf = false
      performanceByMember({...this.listQuery}).then(res => {
        this.viewData = res.data
        this.btnLoading = false
        this.doLoading = false
        this.treeIf = true
      }).catch(err => {
        this.btnLoading = false
        this.doLoading = false
        console.warn(err)
      })
    },
    // getCardInfo() {
    //    getCardType({ 'memberNo': this.listQuery.memberNo }).then(res => {
    //     this.cardInfoObj = res.data
    //   }).catch(() => {})
    // },
    // eslint-disable-next-line no-unused-vars
    // async getClickData(memberNo) {
    //   this.btnLoading = true
    //   this.treeIf = false
    //   await performanceByMember({...this.listQuery, memberNo: memberNo}).then(res => {
    //     this.viewData = res.data.data
    //     this.btnLoading = false
    //     this.treeIf = true
    //   }).catch(err => {
    //     this.btnLoading = false
    //     this.treeIf = false
    //     console.warn(err)
    //   })
    // }
  }
}
</script>
<style scoped lang="scss">
.view-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  text-align: center;
  border: 1px solid #9a9a9a;

  th {
    border: 1px solid #9a9a9a;
  }

  td {
    border: 1px solid #9a9a9a;
  }
}
.table-top {
  width: 580px;
  border-collapse: collapse;
  text-align: center;
  margin-left: 42px;
  border: 1px solid #9a9a9a;

  th {
    border: 1px solid #9a9a9a;
  }

  td {
    border: 1px solid #9a9a9a;
  }
}
.table-content {
  width: 580px !important;
  max-width: 580px !important;
  border-collapse: collapse;
  text-align: center;
  border: 1px solid #9a9a9a;
  overflow: hidden;
  box-sizing: content-box;
  //table-layout: fixed;

  th {
    border: 1px solid #9a9a9a;
  }

  td {
    border: 1px solid #9a9a9a;
  }
}

.pClass {
  margin: 0;
  font-family: "Times New Roman", serif;
}

::v-deep .mytree {
  .el-tree > .el-tree-node:after {
    border-top: none;
  }

  .el-tree-node {
    position: relative;
    padding-left: 16px;
  }

  .el-tree-node__content {
    height: 120px;
  }

  .el-tree-node__expand-icon.is-leaf {
    display: none;
  }

  .el-tree-node__children {
    padding-left: 16px;
  }

  .el-tree-node :last-child:before {
    height: 55px;
  }

  .el-tree > .el-tree-node:before {
    border-left: none;
  }

  .el-tree > .el-tree-node:after {
    border-top: none;
  }

  .el-tree-node:before {
    content: "";
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
  }

  .el-tree-node:after {
    content: "";
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
  }

  .el-tree-node:before {
    border-left: 2px solid #9b9b9b;
    bottom: 0;
    height: 100%;
    top: 0;
    width: 1px;
  }

  .el-tree-node:after {
    border-top: 2px solid #9b9b9b;
    height: 20px;
    top: 55px;
    width: 24px;
  }


  .el-tree > .el-tree-node {
    display: inline-block;
    min-width: 100%;
  }
}

.div_in_table {
  th {
    width: 100px;
  }
}

.levelType0 {
  background-image: linear-gradient(#ffffff, #f7f7f7);
}

.levelType1 {
  background-image: linear-gradient(#cdcdcd, #eaedf1);
}

.levelType2 {
  background-image: linear-gradient(#50d2e7, #4dd5ed);
}

.levelType3 {
  background-image: linear-gradient(#f1dc99, #e1c063);
}

.levelType4 {
  background-image: linear-gradient(#f4748f, #ffa8af);
}
.tl {
  text-align: left !important;
  padding-left: 10px;
}
</style>
